import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Wrapper from "../components/Resultados/wrapper"
import { Router } from "@reach/router"

import Venta from '../templates/propiedades'

const IndexPage = () => (
  <Router basepath="/venta">
    <Venta path="/" />
    <Venta path="/:param-1" />
    <Venta path="/:param-1/:param-2" />
    <Venta path="/:param-1/:param-2/:param-3" />
    <Venta path="/:param-1/:param-2/:param-3/:param-4" />
    <Venta path="/:param-1/:param-2/:param-3/:param-4/:param-5" />
    <Venta path="/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6" />
    <Venta path="/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7" />
    <Venta path="/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7/:param-8" />
    <Venta path="/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7/:param-8/:param-9" />
  </Router>
)

export default IndexPage
